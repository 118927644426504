.ad {
    max-width: 75em;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-flow: column;
}
/* .ad article > ** {
    outline-offset: -1px;
    outline: 1px dotted red;
} */
.ad article {
    display: grid;
    grid-template-areas: 
    'poster poster summary'
    'moreinfo moreinfo summary'
    'rating grades summary'
    'reviews reviews summary';
    grid-template-columns: 30% 30% auto;
    gap: 1em 4em;
}
.ad .poster { grid-area: poster; }
.ad .moreinfo { grid-area: moreinfo; }
.ad .rating { grid-area: rating; }
.ad .grades { grid-area: grades; }
.ad .reviews { grid-area: reviews; }
.ad .actions { grid-area: summary; }
.ad .summary { grid-area: summary; }
.ad .professional { grid-area: summary; }

.ad .poster img {
    width: 100%;
    display: block;
    border-radius: 0.5em;
}

.ad .galery {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    margin: 1.5em 0;
}
.ad .galery li {
    margin: 0 0.75em 0 0;
}
.ad .galery img {
    height: 10em;
    border-radius: 0.5em;
}
.ad .galery li:last-of-type {
    margin: 0;
}
.rating {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    align-content: start;

}
.rating h4 {
    grid-area: 1 / 1 / 2 / span 2;
}
.stars {
    background-image: url("../img/icons/star-off.svg");
    background-size: contain;
    height: 1.96em;
    width: 10em;
}
.rating .stars {
    font-size: 1.25em;
}
.rating output {
    font-size: 2.25em;
    font-weight: 600;
}
.stars .on {
    background-image: url("../img/icons/star-on.svg");
    background-size: contain;
    height: 100%;
}

.dialog.ratings .content {
    text-align: center;
}
.dialog.ratings .content .rating{
    justify-content : center;
}
.dialog.ratings .content h2 {
    margin: 0.5em 0 0 0;
    font-size: 1.4em;
}
.dialog.ratings .content h4 {
    margin: 0.5em;
    font-size: 1.2em;
}


.ad .grades {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.ad .grades ul {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.ad .grades li {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    text-align: center;
    margin: 0 0.75em 0 0;
}
.ad .grades li:last-child {
    margin: 0;
}
.ad .grades li > div {
    background-color: #ddd;
    background-color: rgba(var(--special-color-values), 0.1);
    flex: 1;
    border-radius: 0.25em;
    min-height: 3em;
    display: flex;
    flex-direction: column-reverse;
}
.ad .grades div div {
    border-radius: 0.25em;
}
.ad .grades span {
    font-size: 0.8em;
} 

.ad-actions {
    width: 32%;
    min-width: 21em;
}
.ad .actions {
    position: static;
    margin: 0 0 1em 0;
    width: 100%;
}
.ad .rating-5 {
    background-color: var(--color-rating-5);
}
.ad .rating-4 {
    background-color: var(--color-rating-4);
}
.ad .rating-3 {
    background-color: var(--color-rating-3);
}
.ad .rating-2 {
    background-color: var(--color-rating-2);
}
.ad .rating-1 {
    background-color: var(--color-rating-1);
}

/* REVIEWS  */
.ad .reviews {
    margin-top: 2em;
}

.ad .reviews ul {
    background-color: var(--color-alternate-bg);
    padding: 1em 3em;
}
.ad .reviews li {
    margin: 1em 0;
    padding: 1em 0;
    display: flex;
    flex-flow: row wrap;
}
.ad .reviews .stars {
    font-size: 0.5em;
}
.reviews .stars {
    background-image: url("../img/icons/star-spaced-off.svg");
    width: 14em;
    margin-left: -0.5em;
}               
.reviews .stars .on {
    background-image: url("../img/icons/star-spaced-on.svg");
}
.ad .reviews .useful {
    width: 5em;
    margin: 0 0 0 auto;
    padding: 0;
    background-color: transparent;
    display: flex;
    justify-content: right;
}
.ad .reviews .useful span {
    font-size: 0.8em;
    color: var(--text-gray);
}
.ad .reviews .useful::after {
    content: "";
    background: url("../img/icons/thumb-up-off.svg") center /  contain no-repeat;
    width: 1.5em;
    height: 1em;
}
.ad .reviews h3 {
    font-size: 1em;
    margin: 0.25em 0;
    color: var(--color-text-emphasis);
    width: 100%;
}
.ad .reviews p {
    font-size: 0.9em;
}

.ad .summary {
    margin-top: 2em;
}
.ad .summary p {
    line-height: 1.4;
}
.ad .summary address {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
}
.ad .summary address::before {
    content: "";
    display: inline-block;
    width: 1.25em;
    height: 1em;
    background: url("../img/icons/location.svg") left /  contain no-repeat;
    vertical-align: text-top;
    font-size: 1.2em;
}
.ad .summary button {
    width: 100%;
}
.ad .auati.requester {
    background-color: var(--color-background);
    padding: 0;
    margin: 2em 0 0 0;
    border-radius: 0;
}
.ad .auati.requester button {
    font-size: 1.1em;
    margin: 0;
}

/* PROFESSIONAL */

.professional {
    background-color: rgba(var(--special-color-values), 0.1);
    padding: 1.5em 2em;
    border-radius: 0.5em;
    margin: 2em 0 0 0;
}
.professional header {
    background-color: rgba(var(--special-color-values), 0.1);
    padding: 1.5em 1em;
    display: grid;
    grid-template-areas: 
        'avatar title'
        'avatar date'
        'avatar verification'
    ;
    gap: 0 0.75em;
    color: var(--special-color);
    margin: -1.5em -2em 1.5em -2em;
    border-radius: 0.5em 0.5em 0 0;
}
.professional header figure {
    border-radius: 50%;
    width: 5.8em;
    height: 5.8em;
    background-color: rgba(var(--special-color-values), 0.1);
    overflow: hidden;
    grid-area: avatar;
}
.professional header figure img {
    width: 100%;
    min-height: 100%;
}
.professional header h2 {
    grid-area: 'title';
    margin: 0;
    font-size: 1em;
}
.professional header small {
    grid-area: 'date';
    font-weight: lighter;
}
.professional header .verification {
    grid-area: 'verification';
    display: flex;
    height: 2.5em;
    margin: 0.5em 0 0 0;
}
.professional .verification div {
    flex: 1;
    background: url('../img/icons/verified-email-off.svg') left / contain no-repeat;
    margin: 0 0.125em;
}
.professional .verification .email {
    background-image: url('../img/icons/verified-email-off.svg');
}
.professional .verification .tel {
    background-image: url('../img/icons/verified-tel-off.svg');
}
.professional .verification .doc {
    background-image: url('../img/icons/verified-doc-off.svg');
}
.professional .verification .email.on {
    background-image: url('../img/icons/verified-email-on.svg');
}
.professional .verification .tel.on {
    background-image: url('../img/icons/verified-tel-on.svg');
}
.professional .verification .doc.on {
    background-image: url('../img/icons/verified-doc-on.svg');
}
.professional a.button {
    width: 100%;
    margin: 1em 0 0 0;
}
button.social {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    font-weight: normal;
}
button.social::before,
.professional .links a {
    width: 3em;
    height: 3em;
    display: inline-block;
    overflow: hidden;
    margin: 0 1em 0 0;
    background: transparent center / contain no-repeat;
}
button.social::before {
    content: "";
    margin: 0 0 0.5em 0;
}
button.social span,
.professional .links a span {
    display: none;
}
button.social.facebook::before,
.professional .links a.facebook {
    background-image: url("../img/icons/link-facebook.svg");
}
button.social.whatsapp::before,
.professional .links a.whatsapp {
    background-image: url("../img/icons/link-whatsapp.svg");
}
button.social.instagram::before,
.professional .links a.instagram {
    background-image: url("../img/icons/link-instagram.svg");
}
button.social.linkedin::before,
.professional .links a.linkedin {
    background-image: url("../img/icons/link-linkedin.svg");
}
button.social.company::before,
.professional .links a.linkedin-company {
    background-image: url("../img/icons/link-linkedin-company.svg");
}
button.social.behance::before,
.professional .links a.behance {
    background-image: url("../img/icons/link-behance.svg");
}
button.social.linktree::before,
.professional .links a.linktree {
    background-image: url("../img/icons/link-linktree.svg");
}
button.social.youtube::before,
.professional .links a.youtube {
    background-image: url("../img/icons/link-youtube.svg");
}
button.social.site::before,
.professional .links a.site {
    background-image: url("../img/icons/link-site.svg");
}
button.social.outros::before,
.professional .links a.outros {
    background-image: url("../img/icons/link-outros.svg");
}
.sharing ul {
    display: flex;
    margin: 0 0 1em 0;
    justify-content: space-evenly;
    text-align: center;
}
.sharing li button {
    margin: 0;
}
.sharing .copylink {
    display: flex;
    flex-direction: column;
    background-color: var(--color-alternate-bg);
    padding: 1em;
}
.sharing .copylink span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.sharing .copylink button {
    margin: 1em 0 0 0;
}
/* 
.ad aside {
    width: 30%;
}
.ad-info {
    width: 62%;
}
*/
.ad .auati {
    background-color: rgba(var(--special-color-values), 0.05);
    padding: 1.5em;
    margin: 1.5em -0.1em;
    border-radius: var(--corner-radius);
}
.ad .auati h3 {
    margin: 0 0 0.75em 0;
    color: rgba(var(--special-color-values), 0.9);
    font-size: 1.15em;
}
.ad .auati .list {
}
.ad .auati-list ul {
    margin: 0;
}
.ad .auati.my {
    background-color: rgba(var(--special-bgcolor-values), 0.2);
}

.requestDialog .message {
    padding: 1.5em;
    box-shadow: 1px 1px 4px -1px rgba(0,0,0,0.5);
    background-color: rgba(var(--special-bgcolor-values), 0.2);
    border-radius: var(--corner-radius);
    font-weight: 600;
}
.message br {
    display: block;
    margin: 0.5em 0 0 0;
    content: "";
}
.dialog.responseDialog .chat {
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
}
.responseDialog .chat p {
    margin: 0 0 1em 0;
}
.responseDialog .content {
    background-color: var(--chat-color-bg);
}